import React, { useState, useEffect } from "react";
import axios from "axios";
import { UserAuth } from "../contexts/AuthContext";
import useFetchUserContext from "../hooks/use-userInformation-context"

const CandidatesTab = (props) => {
  const [activeTab, setActiveTab] = useState(props.currentCandidateId)
  const [tabs, setTabs] = useState([])

  const [isLoading, setIsLoading] = useState(true)

  const { CurrentUser } = useFetchUserContext()
  const { dataAPI, ThisCanditate } = UserAuth()

 // console.log(props.currentCandidateId);
  //console.log(tabs);
  //console.log(activeTab)

  // fetch external red

  useEffect(() => {
    const fetchByExternalRef = async () => {

      if (tabs.length !== 0) return

      console.log("fetching external_ref api")
      try {
        const response = await axios.get(
          `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchByExternalRef?external_ref=${props.ExternalRef}`
        );

        const data = response.data;

        loadCandidateTabs(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false)
        props.setIsLoading(false)
      }
    };

    fetchByExternalRef()
  }, [])

  // load candidate tabs
  const loadCandidateTabs = (data) => {
    setTabs(data)
  }

  // Handler to switch tabs

  const handleTabClick = (person) => {
    setActiveTab(person.id);

    const assignment = person.assignments.find(
      (item) => item.external_ref === props.ExternalRef
    );

    person = {
      ...person,
      assignment: assignment,
    };

    handleClick(person);
  }

  const handleClick = (person) => {
    ThisCanditate({
      external_ref: person.assignment.external_ref,
      progress_notes: person.assignment.progress_notes,
      consent_status: person.consent_status,
      assignment_name: person.assignment.assignment_name,
      candidate_internal_comments:
        person.assignment.candidate_internal_comments,
      interview: person.assignment.notes?.interview,
      screening: person.assignment.notes?.screening,
      job_level: person.assignment.job_level,
    })
    CurrentUser(person.id)
    fetchID(person.id)
    props.setLocalFormData({})
  }

  const fetchID = async (value) => {
    props.setIsLoading(true)
    try {
      const response = await axios.get(
        `https://europe-west3-compass-gcp.cloudfunctions.net/Compass_Api_no_auth/api/bigquery/fetchFromID?id=${value}`
      )

      const data = response.data
      dataAPI(data)
      props.setLocalFormData({})
      //props.setTabChanged(true)

    } catch (error) {
      console.log(error)
    } finally {
      props.setIsLoading(false)
    }
  }

  return (
    <div>
      {isLoading ? (
        <div className="loader"></div> // Replace with your loader design
      ) : (
        <div
          className="w-full mx-auto overflow-x-auto"
          key="tabs"
        >
          <div className="flex space-x-4 border-b border-gray-300">
            {tabs.map((person, index) => (
              <button
                onClick={() => handleTabClick(person)}
                className={`text-xs px-4 py-2 transition-colors duration-300 ${
                  activeTab === person.id
                    ? "border-b-2 border-blue-500 font-semibold text-blue-500"
                    : "text-gray-600"
                }`}
              >
                {`${person.first_name} ${person.last_name}`}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export { CandidatesTab };
